import React from 'react';
import { Styles } from 'pages/instructor/styles/instructor';
import { Container, Row, Col } from 'react-bootstrap';
import { colors } from 'components/common/element/elements';
// import Datas from 'data/instructor/details.json';


function Message() {
    return (<div id="principal">
        <Styles>
            <div className="main-wrapper instructor-details-page">
                {/* Instructor Details Area */}
                {/* <section className="instructor-details-area">
                    <Container>
                        <Row>
                            <Col md="4">
                                <div  className="_instructor-img d-flex  justify-content-md-center"  >
                                    <div className="">

                                    <img   src={process.env.PUBLIC_URL + `/assets/custom_images/about/manager.png`} alt="" className="img-fluid" />
                                    </div>
                                    
                                </div>
                            </Col>
                            <Col md="8">
                                <div className="instructor-content">
                                    <h4>Sr. Philomine CSC</h4>
                                    <span>Manager</span>
                                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Veritatis tenetur accusantium laudantium! Vitae libero voluptatum velit earum corrupti officia quo, magnam mollitia nam provident tempora. Ipsum quaerat tempora odit maxime. Lorem ipsum, dolor sit amet consectetur adipisicing elit. Optio, molestiae. Quae illum non sit. In sapiente, reiciendis sunt aperiam repellendus quibusdam repudiandae, dolores tempore esse vero aliquid illum neque voluptatem.<br /><br />Lorem ipsum dolor, sit amet consectetur adipisicing elit. Corrupti, aliquid illo? Quia dolore obcaecati incidunt perferendis minus error repudiandae iure perspiciatis maxime assumenda? Sit dicta, odit sunt maiores incidunt culpa. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Assumenda voluptate quisquam quis officiis.</p>
                                </div>
                                
                            </Col>

                        </Row>
                    </Container>
                </section> */}
                {/* Instructor Details Area */}
                <section style={{ background: colors.bg2 }} className="instructor-details-area">
                    <Container>
                        <Row>
                            <Col md="4">
                                <div className="_instructor-img d-flex  justify-content-md-center"  >
                                    <div className="">

                                        <img src={process.env.PUBLIC_URL + `/assets/custom_images/about/principal.png`} alt="" className="img-fluid" />
                                    </div>

                                </div>
                            </Col>
                            <Col md="8">
                                <div className="instructor-content">
                                    <h4>Sr. Ancy </h4>
                                    <span>Principal</span>
                                    <p>
                                        
                                        Dear Parents,
                                        <br/>
                                        <br/>
                                        Over the years St. Mary's Convent School has come to symbolize the spirit of dedication to the task of maintaining an excellent standard of education. I am fortunate to work with such dedicated, innovative and caring staff members, committed parents and vibrant students. I find each day an adventure filled with new possibilities and chances to constantly widen our horizons. It always gives me great pleasure to see a sea of smiling on the faces of children and I must admit that, they make my day lighter and brighter.
                                        <br/>
                                        <br/>
                                        Aristotle once said that, “Educating the mind without educating the heart is no education at all.” As we impart education to match the advancement in technology and globalization, we march our children ahead with ethos of moral values and principles. Our students are equipped to face the challenges of the rapidly changing world.
                                        <br/>
                                        <br/>
                                        The purpose of this Hand-book is to give parents, guardians, teachers and students a concise source of information on our school, its rules, goals and expectations. It gives the academic and co-curricular activities of the session and acts as a communication link between the School and Parents.
                                        <br/>
                                        <br/>
                                        I am sure through collaborative effort we can achieve more, to benefit our students who are the future leaders of tomorrow.
                                        <br/>
                                        <br/>
                                        Principal
                                        <br/>
                                        St. Mary's Convent School
                                        <br/>

                                        Mariam Nagar
                                        

                                    </p>
                                </div>

                            </Col>

                        </Row>
                    </Container>
                </section>
            </div>
        </Styles>
    </div>);
}

export default Message;